import React, {FC, useEffect} from 'react';
import {RowComponent} from '../../../../core/components/base/row.component';
import {toPersianNumber} from '../../../../core/utils/number.utils';
import {ColumnComponent} from '../../../../core/components/base/column.component';
import {useTranslation} from 'react-i18next';
import {useRouter} from 'next/router';
import {MissionResult, Situation} from '../model/mission.model';
import {MissionRemainTimeComponent} from './missionRemainTime.component';
import {TextComponent} from '../../../../core/components/base/textField.component';
import {COIN_IMAGE} from '../../campaign/CONSTANTS';
import {MISSION} from '../../../../core/navigation/CONSTANTS';
import {MISSION_FLAG_IMAGE_PATH} from '../CONSTANTS';
import Image from 'next/image';

interface MissionItemProps {
  mission: MissionResult;
  width?: string;
}

export const MissionItemComponent: FC<MissionItemProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [isMissionDone, setMissionDone] = React.useState(false);
  const [isMissionActive, setMissionActive] = React.useState(false);
  const [isMissionExpire, setMissionExpire] = React.useState(false);

  useEffect(() => {
    switch (props.mission?.situation) {
      case Situation.DOING: {
        setMissionActive(true);
        break;
      }
      case Situation.DONE: {
        setMissionDone(true);
        break;
      }
      case Situation.REWARDED: {
        setMissionDone(true);
        break;
      }
      case Situation.EXPIRED: {
        setMissionExpire(true);
        break;
      }
      default: {
        setMissionActive(false);
      }
    }
  }, []);

  function navigateToCampaignDetail(missionResult: MissionResult) {
    router.push({ pathname: MISSION + `/${missionResult.id}`, search: `shTitle=${missionResult.title}` });
  }

  return (
    <ColumnComponent
      className={`card card-box text-start mt-2`}
      onClick={() => navigateToCampaignDetail(props.mission)}
    >
      <div className="flex relative w-full">
        <img src={props.mission.icon_url} alt=""
             className="card card-1 aspect-[5/2] !rounded-t-lg !rounded-b-none"/>
        <ColumnComponent
          style={{ display: isMissionDone || isMissionExpire ? 'none' : 'flex' }}
          className="absolute top-2 right-2 text-lg text-center text-white flex-row items-start justify-start"
        >
          <MissionRemainTimeComponent mission={props.mission} />
        </ColumnComponent>
        <ColumnComponent
          style={{ display: isMissionDone || isMissionExpire ? 'none' : 'flex' }}
          className="absolute bottom-2 right-2 text-lg text-center text-white flex flex-row items-start justify-start"
        >
          <TextComponent text={toPersianNumber(props.mission.title ?? '')} isFromTranslate={false} size={'sm'} />
        </ColumnComponent>
        <ColumnComponent
          style={{ display: isMissionDone ? 'flex' : 'none' }}
          className="absolute w-full h-full !rounded-t-lg bg-black/50 text-xlarge text-center text-white flex flex-col totalCenter"
        >
          <TextComponent width={'100%'} text={'clubText.done'} isFromTranslate={true} size={'sm'} />
        </ColumnComponent>
        <div
          style={{ display: isMissionActive ? 'flex' : 'none' }}
          className="absolute top-0 left-0 mx-4 text-lg text-center text-white flex-row items-end justify-end flex"
        >
          <img src={MISSION_FLAG_IMAGE_PATH} alt={''} width={'40rem'} height={'40rem'} className={''} />
          <TextComponent text={'فعال'} className={'absolute text-center top-1 right-1'} isFromTranslate={false} />
        </div>
        <ColumnComponent
          style={{ display: isMissionExpire ? 'flex' : 'none' }}
          className="absolute w-full h-full !rounded-t-lg bg-black/50 text-xlarge text-center text-white flex flex-col totalCenter"
        >
          <TextComponent width={'100%'} text={'clubText.expired'} isFromTranslate={true} size={'sm'} />
        </ColumnComponent>
      </div>
      <ColumnComponent className="p-2 text-start">
        <div className="grow text-sm text-ellipsis h-10 overflow-hidden">{props.mission.description ?? ''}</div>
        <RowComponent className={'totalCenter'}>
          <TextComponent width={'w-auto'} text={t('clubText.prize')} size={'sm'} isFromTranslate={true} />
          <div className={'px-1'}> :</div>
          <Image
            fill={true}
            sizes={'100%'}
            alt={''}
            src={COIN_IMAGE}
            className={`coinImageLarge !relative px-2 ${props.mission.reward_type === 'point' ? 'block' : 'hidden'}`}
          />
          <TextComponent
            className={'text-orange-700 h-8 text-center leading-8'}
            text={props.mission.reward ?? ''}
            isFromTranslate={false}
            size={'sm'}
          />
        </RowComponent>
      </ColumnComponent>
    </ColumnComponent>
  );
};
