import {Observable} from 'rxjs';
import Api from '../../../../core/services/Api';
import {CampaignDetailModel, CampaignModel} from '../model/campaign.model';
import {CAMPAIGN_TOKEN_URL, CAMPAIGN_URL} from '../CONSTANTS';
import {getApiHeaderObjectWithToken} from '../../../../core/services/config';
import {getAppPackage, getLoginAccessToken} from '../../../../core/services/localStorage.service';
import i18n from 'i18next';

export class CampaignService {
  public getCampaigns(isLogin: boolean): Observable<CampaignModel> {
    if (isLogin) {
      return Api.getWithHeader<CampaignModel>(
        CAMPAIGN_TOKEN_URL,
        getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
      );
    } else {
      return Api.get<CampaignModel>(CAMPAIGN_URL);
    }
  }

  public getCampaignsDetail(id: string, isLogin: boolean): Observable<CampaignDetailModel> {
    if (isLogin) {
      return Api.getWithHeader<CampaignDetailModel>(
        CAMPAIGN_TOKEN_URL + id,
        getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
      );
    } else {
      return Api.get<CampaignDetailModel>(CAMPAIGN_URL + id);
    }
  }

  public increaseChance(id: string): Observable<CampaignDetailModel | void> {
    return Api.postWithHeader<CampaignDetailModel>(
      CAMPAIGN_TOKEN_URL + `${id}/participate/`,
      null,
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
    );
  }
}
