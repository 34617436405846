import {Observable} from 'rxjs';
import Api from '../../../../core/services/Api';
import {getApiHeaderObjectWithToken} from '../../../../core/services/config';
import {getAppPackage, getLoginAccessToken, getUserId} from '../../../../core/services/localStorage.service';
import i18n from 'i18next';
import {AUTHENTICATED_MISSION_URL, MISSION_URL} from '../CONSTANTS';
import {MissionDetailModel, MissionMainModel} from '../model/mission.model';

export class MissionService {
  public getMissions(isLogin: boolean, page: number, pageSize: number): Observable<MissionMainModel> {
    if (isLogin) {
      return Api.getWithHeader<MissionMainModel>(
        AUTHENTICATED_MISSION_URL,
        getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken()),
        {
          page_size: pageSize,
          page: page
        }
      );
    } else {
      return Api.get<MissionMainModel>(MISSION_URL, {
        page_size: pageSize,
        page: page
      });
    }
  }

  public getMissionDetail(isLogin: boolean, id: string): Observable<MissionDetailModel> {
    if (isLogin) {
      return Api.getWithHeader<MissionDetailModel>(
        AUTHENTICATED_MISSION_URL + id + '/',
        getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
      );
    } else {
      return Api.get<MissionDetailModel>(MISSION_URL + id + '/');
    }
  }

  public startMission(id: string): Observable<MissionDetailModel | void> {
    return Api.postWithHeader<MissionDetailModel>(
      AUTHENTICATED_MISSION_URL,
      {
        user: getUserId(),
        mission: id
      },
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
    );
  }

  public getReward(id: string): Observable<MissionDetailModel | void> {
    return Api.postWithHeader<MissionDetailModel>(
      AUTHENTICATED_MISSION_URL + 'get_reward/',
      {
        user: getUserId(),
        mission: id
      },
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
    );
  }
}
