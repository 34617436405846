import {DISCOUNT_TOKEN_URL, DISCOUNT_URL, MY_DISCOUNT_TOKEN_URL} from '../CONSTANTS';
import {Observable} from 'rxjs';
import {DiscountModel, UseDiscountModel} from '../model/discount.model';
import Api from '../../../../core/services/Api';
import {getApiHeaderObjectWithToken} from '../../../../core/services/config';
import {getAppPackage, getLoginAccessToken} from '../../../../core/services/localStorage.service';
import i18n from 'i18next';

export class DiscountService {
  public getDiscounts(): Observable<DiscountModel> {
    return Api.get<DiscountModel>(DISCOUNT_URL, {
      PAGE: 1,
      PAGE_SIZE: 50
    });
  }

  public getMyDiscounts(): Observable<DiscountModel> {
    return Api.getWithHeader<DiscountModel>(
      MY_DISCOUNT_TOKEN_URL,
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken()),
      {
        PAGE: 1,
        PAGE_SIZE: 50
      }
    );
  }

  public useDiscounts(id: number): Observable<UseDiscountModel | void> {
    return Api.postWithHeader<UseDiscountModel>(
      `${DISCOUNT_TOKEN_URL}${id}/purchase/`,
      null,
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken())
    );
  }
}
