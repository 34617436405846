import {DAYS_MILLISECONDS, HOURS_MILLISECONDS, MINUTES_MILLISECONDS} from './CONSTANTS';

export function getDaysUntilNow(dayTime: number): string {
  let millis = Math.max(dayTime * 1000 - new Date().getTime(), 0);
  let days = millis / (1000 * 60 * 60 * 24);
  return days.toFixed();
}

export function getHoursUntilNow(dayTime: number): string {
  let millis = Math.max(dayTime * 1000 - new Date().getTime(), 0);
  let hours = (millis % DAYS_MILLISECONDS) / HOURS_MILLISECONDS;
  return hours.toFixed();
}

export function getMinutesUntilNow(dayTime: number): string {
  let millis = Math.max(dayTime * 1000 - new Date().getTime(), 0);
  let minutes = (millis % HOURS_MILLISECONDS) / MINUTES_MILLISECONDS;
  return minutes.toFixed();
}

export function getDaysUntilNowFromDate(dayTime: string): string {
  let millis = Math.max(new Date(dayTime).getTime() - new Date().getTime(), 0);
  let days = millis / (1000 * 60 * 60 * 24);
  return days.toFixed();
}

export function getHoursUntilNowFromDate(dayTime: string): string {
  let millis = Math.max(new Date(dayTime).getTime() - new Date().getTime(), 0);
  let hours = (millis % DAYS_MILLISECONDS) / HOURS_MILLISECONDS;
  return hours.toFixed();
}

export function getMinutesUntilNowFromDate(dayTime: string): string {
  let millis = Math.max(new Date(dayTime).getTime() - new Date().getTime(), 0);
  let minutes = (millis % HOURS_MILLISECONDS) / MINUTES_MILLISECONDS;
  return minutes.toFixed();
}
