import React, {FC} from 'react';
import {ColumnComponent} from '../../../../core/components/base/column.component';
import {RowComponent} from '../../../../core/components/base/row.component';
import {toPersianNumberFromNumber} from '../../../../core/utils/number.utils';
import {COIN_IMAGE} from '../../campaign/CONSTANTS';
import {DiscountResults} from '../model/discount.model';
import {useTranslation} from 'react-i18next';
import Image from 'next/image';

interface DiscountItemProps {
  discount: DiscountResults;
  itemClick: (discount: DiscountResults) => void;
}

export const DiscountItemComponent: FC<DiscountItemProps> = (props) => {
  const { t } = useTranslation();
  return (
    <RowComponent className="py-1 items-center">
      <div className="flex flex-col w-1/4 justify-center align-start">
        <ColumnComponent className="totalCenter">
          <Image fill={true} sizes={'100%'} src={props.discount.image} alt={''} className="discountImage !relative" />
          <div className="text-sm text-center mb-4">{props.discount.provider}</div>
        </ColumnComponent>
      </div>
      <div className="mDividerDashed" />
      <div className="flex flex-col w-3/4 items-center px-3">
        <RowComponent className="totalCenter">
          <div className="grow fs-7 mt-1 text-start w-full">{props.discount.title}</div>
          <div className="text-base mx-1">{toPersianNumberFromNumber(props.discount.cost)}</div>
          <img alt={''} src={COIN_IMAGE} className="littleCoinImage" />
        </RowComponent>
        <RowComponent className="items-center">
          <div className="grow mTextBlue text-start text-sm mt-2 text-bold w-full">
            {toPersianNumberFromNumber(props.discount.available)} {t('exist')}
          </div>
          <div className="mt-2 greenButton px-2 width-auto" onClick={() => props.itemClick(props.discount)}>
            {t('moreInformation')}
          </div>
        </RowComponent>
      </div>
    </RowComponent>
  );
};
