import React, {FC} from 'react';
import {RemainTimeComponent} from './remainTime.component';
import {RowComponent} from '../../../../core/components/base/row.component';
import {toPersianNumber} from '../../../../core/utils/number.utils';
import {ColumnComponent} from '../../../../core/components/base/column.component';
import {useTranslation} from 'react-i18next';
import {useRouter} from 'next/router';
import {CampaignResults} from '../model/campaign.model';
import {CAMPAIGN} from '../../../../core/navigation/CONSTANTS';

interface ActiveCampaignItemProps {
  campaign: CampaignResults;
  width?: string;
}

export const ActiveCampaignItemComponent: FC<ActiveCampaignItemProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();

  function navigateToCampaignDetail(campaignResult: CampaignResults) {
    router.push({ pathname: CAMPAIGN + `/${campaignResult.id}`, search: `shTitle=${campaignResult.title}` });
  }

  return (
    <ColumnComponent
      className={`card card-box text-start mt-2 pointer`}
      onClick={() => navigateToCampaignDetail(props.campaign)}
    >
      <div className="flex relative w-full">
        <img src={props.campaign.image} alt=""
             className="card card-1 activeCampaignImage !rounded-t-lg !rounded-b-none"/>
        <div className="absolute top-2 right-2 text-lg text-center text-white flex flex-row items-center justify-start">
          <RemainTimeComponent campaign={props.campaign} />
        </div>
      </div>
      <RowComponent className="p-2 text-start totalCenter">
        <div className="grow text-base px-1 text-ellipsis max-h-6 overflow-hidden">
          {toPersianNumber(props.campaign.title)}
        </div>
        {props.campaign.user_current_chances?.length ? (
          <div className="flex flex-row totalCenter">
            <div className="w-auto text-center p-1 mx-1 circleChance text-white mBackgroundGold rounded-full">
              {toPersianNumber(props.campaign.user_current_chances?.length.toString() ?? '0')}
            </div>
            <div className="w-auto ps-1 mTextGold"> {t('clubText.chance')}</div>
          </div>
        ) : null}
      </RowComponent>
    </ColumnComponent>
  );
};
