import React, {FC} from 'react';
import {toPersianNumber} from '../../../../core/utils/number.utils';
import {getDaysUntilNow, getHoursUntilNow, getMinutesUntilNow} from '../campaign.utils';
import {useTranslation} from 'react-i18next';
import {CampaignResults} from '../model/campaign.model';
import {RowComponent} from '../../../../core/components/base/row.component';

interface RemainTimeProps {
  campaign: CampaignResults;
}

export const RemainTimeComponent: FC<RemainTimeProps> = (props) => {
  const { t } = useTranslation();
  return (
    <RowComponent className="grow totalCenter text-black">
      <div className="flex flex-col totalCenter bg-white/90 rounded-md px-2">
        <div className={'mTextBlue'}>{toPersianNumber(getMinutesUntilNow(props.campaign?.finish_date))}</div>
        <div className="fs-8">{t('clubText.minute')}</div>
      </div>
      <div className="text-lg px-1 text-white">:</div>
      <div className="flex flex-col totalCenter bg-white/90 rounded-md px-2">
        <div className={'mTextBlue'}>{toPersianNumber(getHoursUntilNow(props.campaign?.finish_date))}</div>
        <div className="fs-8">{t('clubText.hour')}</div>
      </div>
      <div className="text-lg px-1  text-white">:</div>
      <div className="flex flex-col totalCenter bg-white/90 rounded-md px-2">
        <div className={'mTextBlue'}>{toPersianNumber(getDaysUntilNow(props.campaign?.finish_date))}</div>
        <div className="fs-8">{t('clubText.day')}</div>
      </div>
    </RowComponent>
  );
};
