import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppBarClassKey, PaperProps, StandardProps } from '@mui/material';

export interface ComponentProps extends StandardProps<PaperProps, AppBarClassKey> {
  size?: string;
  text: string;
  isFromTranslate: boolean;
  align?: string;
  width?: string;
  color?: string;
}

export function TextComponent(props: ComponentProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div
      className={`text-${props.align ?? 'start'} ${props.width ?? 'w-full'} text-${props.size ?? 'base'} ${
        props.className
      }`}
      onClick={props.onClick}
      style={props.style}
    >
      {props.isFromTranslate ? t(props.text) : props.text}
    </div>
  );
}
