import React, {FC} from 'react';
import {toPersianNumber} from '../../../../core/utils/number.utils';
import {useTranslation} from 'react-i18next';
import {RowComponent} from '../../../../core/components/base/row.component';
import {MissionResult} from '../model/mission.model';
import {getDaysUntilNowFromDate, getHoursUntilNowFromDate} from '../../campaign/campaign.utils';

interface RemainTimeProps {
  mission: MissionResult;
}

export const MissionRemainTimeComponent: FC<RemainTimeProps> = (props) => {
  const { t } = useTranslation();
  return (
    <RowComponent className="grow text-black items-center">
      <div className="flex flex-col totalCenter bg-white/90 rounded-sm px-2">
        <div>{toPersianNumber(getDaysUntilNowFromDate(props.mission?.end))}</div>
        <div className="fs-8">{t('clubText.minute')}</div>
      </div>
      <div className="text-lg px-1 text-white">:</div>
      <div className="flex flex-col totalCenter bg-white/90 rounded-sm px-2">
        <div>{toPersianNumber(getHoursUntilNowFromDate(props.mission?.end))}</div>
        <div className="fs-8">{t('clubText.hour')}</div>
      </div>
      <div className="text-lg px-1 text-white">:</div>
      <div className="flex flex-col totalCenter bg-white/90 rounded-sm px-2">
        <div>{toPersianNumber(getDaysUntilNowFromDate(props.mission?.end))}</div>
        <div className="fs-8">{t('clubText.day')}</div>
      </div>
    </RowComponent>
  );
};
