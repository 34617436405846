import React from 'react';

export interface MissionMainModel {
  error: React.ReactNode;
  data: MissionDataModel;
}

export interface MissionDetailModel {
  error: React.ReactNode;
  data: MissionResult;
}

export interface MissionDataModel {
  count: number;
  next: string;
  previous: string;
  total_point: number;
  results: MissionResult[];
}

export interface MissionResult {
  id: number;
  title: string;
  event: string;
  icon_url: string;
  reward_type: string;
  reward: string;
  reward_title_url: string;
  reward_url: string;
  reward_description: string;
  description: string;
  point?: number;
  cost?: number;
  start: string;
  end: string;
  duration_days: string;
  duration_hours: string;
  count: number;
  type: string;
  is_ordered: boolean;
  is_active: boolean;
  is_done: boolean;
  re_start: boolean;
  detail: string;
  stages: MissionResultStage[];
  situation: Situation;
  timeout: string;
}

export enum Situation {
  NONE = 'none',
  DOING = 'doing',
  DONE = 'done',
  REWARDED = 'rewarded',
  EXPIRED = 'expire'
}

export interface MissionResultStage {
  id: number;
  title: string;
  event: string;
  icon_url: string;
  order: number;
  count: number;
  um_count: number;
  is_done: boolean;
  description: string;
  detail: string;
}
